// Vérifie si l'élément avec l'ID 'back-to-top' existe
var backToTopButton = document.getElementById('back-to-top');

if (backToTopButton) {

    var scrollTrigger = 100; // px

    // Fonction pour afficher ou masquer le bouton en fonction du défilement
    var backToTop = function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > scrollTrigger) {
            backToTopButton.classList.add('show');
        } else {
            backToTopButton.classList.remove('show');
        }
    };

    backToTop();

    // Ajoute un écouteur d'événement de défilement
    window.addEventListener('scroll', function () {
        backToTop();
    });

    // Ajoute un écouteur d'événement de clic sur le bouton
    backToTopButton.addEventListener('click', function (e) {
        e.preventDefault();

        // Anime le défilement vers le haut
        document.body.scrollTop = 0; // Pour les navigateurs Safari
        document.documentElement.scrollTop = 0; // Pour les autres navigateurs
    });
}
