const searchButton = document.querySelector('.search-button .button');
const searchForm = document.getElementById('search-form');

let clickCount = 0; // Compteur de clics

// Vérifiez si le bouton a été trouvé avant d'ajouter l'événement de clic
if (searchButton) {
    // Ajoutez un gestionnaire d'événements au clic sur le bouton
    searchButton.addEventListener('click', function () {
        if (clickCount % 2 === 0) {
            // Si le nombre de clics est pair, ajoutez la classe "active"
            searchForm.classList.add('active');
        } else {
            // Si le nombre de clics est impair, supprimez la classe "active"
            searchForm.classList.remove('active');
        }
        // Incrémentez le compteur de clics
        clickCount++;
    });
}