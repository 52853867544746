let openers = document.querySelectorAll('.kl-modal-opener');
openers.forEach(opener => {
    opener.addEventListener('click', () => {
        let modal = document.querySelector('.kl-modal');
        modal.style.display = 'block';
        modal.style.opacity = '1';
    });
});

let closed = document.querySelectorAll('.kl-modal-close');
closed.forEach(close => {
    close.addEventListener('click', () => {
        event.preventDefault();
        let modal = document.querySelector('.kl-modal');
        modal.style.display = 'none';
        modal.style.opacity = '0';
    });
});