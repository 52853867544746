/* Swiper News */
var swiper = new Swiper("#news-slider", {
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    grabCursor: true,
    speed: 800,
    autoplay: {
        delay: 3000,
        pauseOnMouseEnter : true,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".news-next",
        prevEl: ".news-prev",
    },
    breakpoints: {
        // when window width is >= 640px
        640: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
        // when window width is >= 769px
        769: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
        // when window width is >= 992px
        992: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
    }
});

/* Swiper Events */
var swiper = new Swiper("#events-slider", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    grabCursor: true,
    speed: 800,
    autoplay: {
        delay: 3000,
        pauseOnMouseEnter : true,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".events-next",
        prevEl: ".events-prev",
    },
});

/* Slider formations alternance */
var swiper = new Swiper("#alternance-slider", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    grabCursor: true,
    speed: 800,
    autoplay: {
        delay: 3000,
        pauseOnMouseEnter : true,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".alt-next",
        prevEl: ".alt-prev",
    },
    breakpoints: {
        // when window width is >= 640px
        640: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
        // when window width is >= 992px
        992: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
    }
});