document.addEventListener("DOMContentLoaded", function () {
  var buttons = document.querySelectorAll('.step-button');
  var contents = document.querySelectorAll('.steps-contents_item');

  buttons.forEach(function (button) {
      button.addEventListener('click', function () {
          var step = this.getAttribute('data-step');

          // Remove active class from all buttons
          buttons.forEach(function (btn) {
              btn.classList.remove('active');
          });

          // Add active class to the clicked button
          this.classList.add('active');

          // Remove active class from all contents
          contents.forEach(function (content) {
              content.classList.remove('active');
          });

          // Add active class to the corresponding content
          var correspondingContent = document.querySelector('.steps-contents_item[data-step="' + step + '"]');
          correspondingContent.classList.add('active');
      });
  });
});