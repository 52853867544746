/*function animateValue(obj, start, end, duration, suffix) {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const animatedValue = Math.floor(progress * (end - start) + start);
        obj.innerHTML = animatedValue + suffix; // Réinsérer le suffixe
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}

document.addEventListener("DOMContentLoaded", function () {
    const elements = document.querySelectorAll(".data-value-count");

    elements.forEach((element) => {
        const startValue = 0;

        // Exclure les balises <span> temporairement
        const spanContent = element.innerHTML.match(/<span[^>]*>.*?<\/span>/g);
        const contentWithoutSpan = element.innerHTML.replace(/<\/?span[^>]*>/g, '');

        const endValue = parseInt(contentWithoutSpan.trim(), 10);
        const duration = 5000; // Animation duration in milliseconds

        animateValue(element, startValue, endValue, duration, spanContent);
    });
});*/


function animateValue(obj, start, end, duration, suffix) {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const animatedValue = Math.floor(progress * (end - start) + start);
        obj.innerHTML = animatedValue + suffix; // Réinsérer le suffixe
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}

function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const element = entry.target;
            const startValue = 0;

            const spanContent = element.innerHTML.match(/<span[^>]*>.*?<\/span>/g);
            const contentWithoutSpan = element.innerHTML.replace(/<\/?span[^>]*>/g, '');

            const endValue = parseInt(contentWithoutSpan.trim(), 10);
            const duration = 5000; // Animation duration in milliseconds

            animateValue(element, startValue, endValue, duration, spanContent);

            // Une fois que l'animation est déclenchée, vous pouvez cesser d'observer cet élément
            observer.unobserve(element);
        }
    });
}

document.addEventListener("DOMContentLoaded", function () {
    const elements = document.querySelectorAll(".data-value-count");

    const options = {
        threshold: 0.5 // Ajustez cette valeur en fonction de vos besoins
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    elements.forEach((element) => {
        observer.observe(element);
    });
});
