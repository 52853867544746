document.addEventListener('DOMContentLoaded', function() {
    document.addEventListener('click', function(e) {
        if (e.target.classList.contains('upload-menu-item-image')) {
            e.preventDefault();

            var itemId = e.target.getAttribute('data-item-id');
            var customImage = wp.media({
                title: 'Select Image',
                button: {
                    text: 'Use this image'
                },
                multiple: false
            }).on('select', function() {
                var attachment = customImage.state().get('selection').first().toJSON();
                var inputField = document.getElementById('edit-menu-item-image-' + itemId);
                inputField.value = attachment.id;

                var previewImage = inputField.parentElement.parentElement.querySelector('.menu-item-image-preview');
                previewImage.src = attachment.url;
                previewImage.style.display = 'block';

                var removeButton = inputField.parentElement.querySelector('.remove-menu-item-image');
                removeButton.style.display = 'inline-block';
            }).open();
        }

        if (e.target.classList.contains('remove-menu-item-image')) {
            e.preventDefault();

            var itemId = e.target.getAttribute('data-item-id');
            var inputField = document.getElementById('edit-menu-item-image-' + itemId);
            inputField.value = '';

            var previewImage = inputField.parentElement.parentElement.querySelector('.menu-item-image-preview');
            previewImage.src = '';
            previewImage.style.display = 'none';

            e.target.style.display = 'none';
        }
    });
});
